import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate, useNavigate} from "react-router-dom";
import { PrivateRoute } from './components/auth/PrivateRoute';
import { Layout } from 'antd';
const { Content } = Layout;

import { setLocale } from './store/reducers/locale';
import { setLocation } from './store/reducers/location';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { useTranslation } from "react-i18next";

const HomePage = lazy(() => import('./pages/HomePage'));
const PremiumIndexF = lazy(() => import('./pages/premium/index'));
const ShoppingListIndex = lazy(() => import('./pages/shoppinglist/index'));
const LoginPageF = lazy(() => import('./pages/auth/LoginPage'));
const RegistrationF = lazy(() => import('./pages/auth/Registration'));
const PasswordEditF = lazy(() => import('./pages/auth/PasswordEdit'));
const PasswordRecoveryF = lazy(() => import('./pages/auth/PasswordRecovery'));
const OfertaPage = lazy(() => import('./new_format/components/other/OfertaPage'));
const OfertaPageF = lazy(() => import('./pages/other/OfertaPage'));
const UserSider = lazy(() => import('./new_format/pages/user/UserSider'));
const UserFooter = lazy(() => import('./new_format/pages/user/UserFooter'));
const UserHeader = lazy(() => import('./new_format/pages/user/UserHeader'));
const RecipeList = lazy(() => import('./new_format/components/Recipes/RecipeList'));
const RecipeShowForm = lazy(() => import('./new_format/components/Recipes/RecipeShowForm'));
const RecipeEditForm = lazy(() => import('./new_format/components/Recipes/RecipeEditForm'));
const PlansList = lazy(() => import('./new_format/components/Mealplans/PlansList'));
const Profile = lazy(() => import('./new_format/components/Settings/Profile'));
const Dashboard = lazy(() => import('./new_format/components/Dashboard/Dashboard'));
const Subscribe = lazy(() => import('./new_format/components/Settings/Subscribe'));
const PlanTemplatesIndex = lazy(() => import('./new_format/components/PlanTemplates/PlanTemplatesIndex'));
const TemplateEditForm = lazy(() => import('./new_format/components/PlanTemplates/TemplateEditForm'));
const SettingsGenerationMeals = lazy(() => import('./new_format/components/Mealplans/SettingsGenerationMeals'));





import { defineLanguage } from './_helpers/common_functions'

//14.28
//17.43

function App(props) {
    if(!props.t)
    {
        const {setLocale, setLocation} = props
        defineLanguage(window.location.pathname.substring(1, 3))
        const {t, i18n} = useTranslation();

        setLocale(t, i18n)
        setLocation(window.location);
    }
    if(window.location.pathname.substring(0, 8) === "/uk/menu" || window.location.pathname.substring(0, 8) === "/ru/menu") {
        return <div className="App">
                <Layout className="user-layout">
                    <Suspense fallback={<div></div>}>
                        <Routes>
                            <Route path="*" element={<Navigate to="/"/>} exact/>
                        </Routes>
                    </Suspense>
                </Layout>
            </div>
    }
    if(window.location.pathname.substring(0, 8) === "/uk/user" || window.location.pathname.substring(0, 8) === "/ru/user"){
       const history = useNavigate();
       return <div className="App">
            <Layout className="user-layout">
                <UserSider history = {history}/>
                <Layout>
                    <UserHeader  history = {history}/>
                    <Content className="user-content">
                            <Suspense fallback={<div></div>}>
                                <Routes>
                                    <Route path="/:locale" >
                                        <Route path="" element={<RecipeList/>}/>

                                        <Route path="user">
                                            <Route index element={<Navigate to={"dashboard"}/>}/>
                                            <Route path="dashboard">
                                                <Route index exact element={<PrivateRoute>
                                                    <Dashboard history = {history} />
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="recipes">
                                                <Route index element={<PrivateRoute>
                                                    <RecipeList history = {history} is_loading = {false}/>
                                                </PrivateRoute>}/>
                                                <Route path="create" element={<PrivateRoute>
                                                    <RecipeEditForm history = {history} />
                                                </PrivateRoute>}/>
                                                <Route exact path=":id" element={<PrivateRoute>
                                                    <RecipeShowForm history = {history }/>
                                                </PrivateRoute>}/>
                                                <Route exact path="edit/:id" element={<PrivateRoute>
                                                    <RecipeEditForm history = {history }/>
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="mealplans">
                                                <Route index exact element={<PrivateRoute>
                                                    <PlansList history = {history} />
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="parametersmealplan">
                                                <Route index exact element={<PrivateRoute>
                                                    <SettingsGenerationMeals history = {history} />
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="plantemplates">
                                                <Route index exact element={<PrivateRoute>
                                                    <PlanTemplatesIndex history = {history} />
                                                </PrivateRoute>}/>
                                                <Route path="create" element={<PrivateRoute>
                                                    <TemplateEditForm history = {history} />
                                                </PrivateRoute>}/>
                                                <Route path=":id" element={<PrivateRoute>
                                                    <TemplateEditForm history = {history} />
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="grocerylist">
                                                <Route index exact element={<PrivateRoute>
                                                    <ShoppingListIndex history = {history} />
                                                </PrivateRoute>}/>
                                            </Route>
                                            <Route path="profile" element={<PrivateRoute>
                                                <Profile/>
                                            </PrivateRoute>}/>
                                            <Route path="subscription" element={<PrivateRoute>
                                                <Subscribe/>
                                            </PrivateRoute>}/>
                                            <Route path="oferta" element={<PrivateRoute>
                                                <OfertaPage/>
                                            </PrivateRoute>}/>
                                        </Route>
                                    </Route>
                                </Routes>
                            </Suspense>
                    </Content>
                    <UserFooter/>
                </Layout>
            </Layout>
        </div>
   }else {
       return <div className="App">
           <Layout className="user-layout">
           <Suspense fallback={<div></div>}>
               <Routes>
                   <Route path="/" element={<Navigate to="/uk"/>} exact/>
                   <Route path="/:locale">
                       <Route index element={<HomePage/>}/>
                       <Route path="price" element={<PremiumIndexF/>}/>
                       <Route path="oferta" element={<OfertaPageF/>}/>
                       <Route path="auth">
                           <Route path="login" element={<LoginPageF/>}/>
                           <Route path="registration" element={<RegistrationF/>}/>
                           <Route path="password/recovery" element={<PasswordEditF/>}/>
                           <Route path="password/edit" element={<PasswordRecoveryF/>}/>}
                       </Route>
                   </Route>
                   <Route path="*" element={<p>Path not resolved</p>}/>
               </Routes>
           </Suspense>
           </Layout>
       </div>
   }
}

function mapStateToProps (state) {
    return {
        date_update: state.date_update,
        t: state.locale.t
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    setLocale: setLocale,
    setLocation: setLocation
}, dispatch)

export default connect(mapStateToProps,
    mapDispatchToProps)(App)