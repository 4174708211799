import { LOAD_DATA_PROFILE, UPDATE_CAL_IPD, UPDATE_DATE_UPDATE,
        LOAD_FILTER_RECIPES, CHANGE_CATEGORIES, CHANGE_CURRENT_PAGE, CHANGE_FILTER_INGREDIENTS, CHANGE_MINMAX_KKAL, CHANGE_FILTER_NAME_RECIPE,
        LOAD_DATA_RECIPES, LOAD_COUNT_RECIPES, LOAD_MAX_KKAL, SET_RECIPE_NUMBER, LOAD_DATA_RECIPE, SET_RECIPE_FAVORITE, SET_RECIPE_NUM_SERVINGS,
        SET_OPEN_MODAL_FIELD_MENU, SET_FIELD_MENU, SET_ITEM_MENU, SET_ITEM_MENU_CONSTRUCTOR, SET_FIELD_MENU_CONSTRUCTOR,
        SET_LOCALE, SET_LOCATION, CHANGE_FILTER_FAVORITES,
        LOAD_MENU_TEMPLATE, SET_NAME_TEMPLATE, SET_TYPE_TEMPLATE, SET_LIST_ITEMS_TEMPLATE} from '../action-types'
import moment from 'moment';
import empty_foto from '../../images/empty_foto.gif';

const initialState = {
    date_update: "",
    profile: {access: 'visitor',
            prem_already_activ: true,
            prem_day_left: 0,
            cal_IPD: 2000,
            protein_IPD: 150,
            fat_IPD: 44,
            сarbs_IPD: 250,
            daily_cash_limit: 200,
            country_code: "",
            used_shopping_list: false},
    filterRecipes: {listIngredients: [],
        filterIngedients: [],
        treeCategories: [],
        filterNameRecipe: "",
        maxKcalInRecipes: 0,
        maxKcal: 0,
        minKcal: 0,
        filterCategorie: '',
        filterSubcategorie: '',
        sizePagination: Math.round((window.innerWidth/38*19)/240)*6,
        currentPage: 1,
        countRecipes: 0},
    menuItem: {
        typeMealId: "",
        dateRecipe: moment(),
        menuId: "",
        num_servings: 4,
        weightServing: "",
        weightServingFact: "",
        recipeId: "",
        no_cooking_required: false,
    },
    menuItemConstructor: {
        typeMealId: "",
        dayInWeek: 0,
        menuId: "",
        num_servings: 1,
        weightServing: "",
        recipeId: ""
    },
    recipe: {
        recipeNumber: "",
        name: "",
        description: "",
        id: "",
        categoryId: "",
        subcategoryId: "",
        сarbs: 0,
        kkal: 0,
        protein: 0,
        fat: 0,
        favorite: false,
        imgRecipe: empty_foto,
        tabCompositions: [],
        tabStages: [],
        num_servings: "",
        weight_dish: 0,
        weightIngredients: 0,
        sourceUrlRecipe: "",
        myRecipe: false,
        weightServing: 0,
        priceServing: 0
    },
    locale: {t: "",
        i18n: "",
    },
    listRecipes: [],
    menuTemplate: {
        name: "",
        template_number: "",
        list_items: [],
        menu_template_type_id: "",
    },
}

export const profileReducer =  (state = initialState, action) => {
    switch (action.type) {
        case LOAD_DATA_PROFILE:
            return {
                ...state,
                profile: action.profile,
            }
        case UPDATE_CAL_IPD:
            return {
                ...state,
                profile: {...state.profile,
                    [action.name]: action.value},
            }
        case UPDATE_DATE_UPDATE:
            return {
                ...state,
                date_update: action.date_update,
            }
        case LOAD_COUNT_RECIPES:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    countRecipes: action.countRecipes},
            }
        case LOAD_FILTER_RECIPES:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    treeCategories: action.treeCategories,
                    listIngredients: action.listIngredients,
                    maxKcalInRecipes: action.maxKcalInRecipes,
                    maxKcal: action.maxKcal},
            }
        case LOAD_MAX_KKAL:
            return {
                ...state,
                filterRecipes: {
                    ...state.filterRecipes,
                    maxKcalInRecipes: action.maxKcalInRecipes,
                    maxKcal: action.maxKcal
                },
            }
        case CHANGE_FILTER_NAME_RECIPE:
            return {
                ...state,
                filterRecipes: {
                    ...state.filterRecipes,
                    filterNameRecipe: action.filterNameRecipe
                },
            }
        case CHANGE_FILTER_FAVORITES:
            return {
                ...state,
                filterRecipes: {
                    ...state.filterRecipes,
                    filterFavorites: action.filterFavorites
                },
            }
        case CHANGE_CATEGORIES:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    filterCategorie: action.filterCategorie,
                    filterSubcategorie: action.filterSubcategorie,
                    currentPage: 1},
            }
        case CHANGE_FILTER_INGREDIENTS:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    filterIngedients: action.filterIngedients,
                    currentPage: 1},
            }
        case LOAD_DATA_RECIPES:
            return {
                ...state,
                listRecipes: action.listRecipes,
            }
        case CHANGE_MINMAX_KKAL:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    [action.name]: action.value},
            }
        case CHANGE_CURRENT_PAGE:
            return {
                ...state,
                filterRecipes: {...state.filterRecipes,
                    sizePagination: action.sizePagination,
                    currentPage: action.currentPage},
            }
        case SET_OPEN_MODAL_FIELD_MENU:
            return {
                ...state,
                menuItem: {...state.menuItem,
                    recipeId: action.recipeId,
                    num_servings: action.num_servings,
                    weightServing: action.weightServing,
                    weightServingFact: action.weightServingFact},
            }
        case SET_FIELD_MENU:
            return {
                ...state,
                menuItem: {...state.menuItem,
                    [action.name]: action.value},
            }
        case SET_FIELD_MENU_CONSTRUCTOR:
            return {
                ...state,
                menuItemConstructor: {...state.menuItemConstructor,
                    [action.name]: action.value},
            }
        case SET_ITEM_MENU:
            return {
                ...state,
                menuItem: action.menuItem,
            }
        case SET_ITEM_MENU_CONSTRUCTOR:
            return {
                ...state,
                menuItemConstructor: action.menuItemConstructor,
            }
        case SET_LOCALE:
            return {
                ...state,
                locale: {...state.locale,
                    t: action.t,
                    i18n: action.i18n},
            }
        case SET_LOCATION:
            return {
                ...state,
                location: {...state.location},
            }
        case SET_RECIPE_NUMBER:
            return {
                ...state,
                recipe: {...state.recipe,
                    recipeNumber: action.recipeNumber},
            }
        case SET_RECIPE_NUM_SERVINGS:
            return {
                ...state,
                recipe: {...state.recipe,
                    num_servings: action.num_servings},
            }
        case LOAD_DATA_RECIPE:
            return {
                ...state,
                recipe: action.recipe,
            }
        case SET_RECIPE_FAVORITE:
            return {
                ...state,
                recipe: {...state.recipe,
                    favorite: action.favorite},
            }
        case LOAD_MENU_TEMPLATE:
            return {
                ...state,
                menuTemplate: action.menuTemplate,
            }
        case SET_NAME_TEMPLATE:
            return {
                ...state,
                menuTemplate: {...state.menuTemplate,
                    name: action.name},
            }
        case SET_TYPE_TEMPLATE:
            return {
                ...state,
                menuTemplate: {...state.menuTemplate,
                    menu_template_type_id: action.menu_template_type_id},
            }
        case SET_LIST_ITEMS_TEMPLATE:
            return {
                ...state,
                menuTemplate: {...state.menuTemplate,
                    list_items: action.list_items},
            }
        default:
            return state
    }
}