import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { profileReducer } from './reducers/reducer';

const middlewares = [thunk];


export default function configureStore(initialState) {
    const store = createStore(
        profileReducer, applyMiddleware(...middlewares)
    );
    return store
}