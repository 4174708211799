export function defineLanguage(pathname) {
    let lng = (pathname === "uk" ? "uk" : "ru")
    if (localStorage.lng !== lng) {

        localStorage.setItem('lng', lng);
    }
    window.document.documentElement.lang = localStorage.lng;
    return lng
}

