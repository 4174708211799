import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { history } from '../../_helpers';

export { PrivateRoute };

import { authenticationService } from './authentication.service';

function PrivateRoute({ children }) {
    const authUser = authenticationService.currentUserValue;
    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/ua/auth/login" state={{ from: history.location }} />
        // return <Navigate to={`${localStorage.lng}/login`} state={{ from: history.location }} />
    }

    // authorized so return child components
    return children;
}

