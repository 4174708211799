import $ from 'jquery';
import axios from 'axios'
import { apiUrl, siteUrl } from '../../_helpers/const'



class Auth {
    static isLogIn() {
        return !!localStorage.user;
    }

    static isNotLogin() {
        if(!Auth.isLogIn()){
            window.location.reload(`/${localStorage.lng}/auth/login`);
        }
    }

    static logOut() {
        if(!Auth.isLogIn()){
            window.location.reload(`/${localStorage.lng}/auth/login`);
        }else {
            $.ajax({
                type: 'DELETE',
                url: `${apiUrl}/auth/sign_out`,
                data: JSON.parse(localStorage.user)
            })
                .done(() => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('profile');
                    localStorage.removeItem('profile_data');
                    window.location.href = `${siteUrl}/${localStorage.lng}/auth/login`;
                })
                .catch(error => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('profile');
                    localStorage.removeItem('profile_data');
                    window.location.reload(`/${localStorage.lng}/auth/login`);
                })
        }
    }

    static getSession()
    {
        if (Auth.isLogIn()) {
            return JSON.parse(localStorage.user)
        }
    }

    static async newPassword(email){
        let res = await axios.post(`${apiUrl}/auth/password`,
            {
                email: email,
                client_config: email,
                redirect_url: email,
                only_path: true,
                host: apiUrl,
            });
        return res.data;
    }
}

export default Auth;